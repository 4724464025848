<template>
  <div>
    <div class="_Tab">
      <span
        v-for="(item, index) in ['计划列表', '检查列表']"
        :key="index"
        :class="{ activeColor: index == 1 }"
        @click="clickTabItem(index)"
      >
        {{ item }}
      </span>
    </div>
    <div class="_SelectHeader select-box">
      <div class="_Select">
        <span style="width: 60px">公司：</span>
        <el-select
          v-model="company"
          clearable
          placeholder="请选择"
          filterable
          @change="changeCompy"
        >
          <el-option
            v-for="item in companyList"
            :key="item.id"
            :label="item.companyName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="_Select">
        <span style="width: 60px">部门：</span>
        <el-select v-model="dept" clearable placeholder="请选择">
          <el-option
            v-for="item in deptList"
            :key="item.id"
            :label="item.deptmentName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <el-button icon="el-icon-search" @click="toSearch"></el-button>
    </div>
    <div class="_TableBox" :style="{ height: elementHeight + 70 } + 'px'">
      <el-table
        ref="multipleTable"
        :data="tableData.slice(start, end)"
        tooltip-effect="dark"
        style="width: 100%"
        :height="elementHeight"
        highlight-current-row
        @row-dblclick="change"
        @row-click="handleRow"
        :cell-style="cellStyle"
        :stripe="true"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column label="岗位" prop="postName" width="120">
        </el-table-column>
        <el-table-column prop="companyName" label="所属公司" width="230">
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-show="scope.row.status === 0">未抽题</span>
            <span v-show="scope.row.status === 1">已抽题</span>
            <span v-show="scope.row.status === 2">完成</span>
            <span v-show="scope.row.status === 3">整改完成</span>
            <span v-show="scope.row.status === 4">未完成</span>
          </template>
        </el-table-column>
        <el-table-column prop="completeTime" label="检查时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime.split("T")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime.split("T")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="chargeUserName" label="检查员" width="200">
        </el-table-column>
        <el-table-column prop="saveUserName" label="创建人"> </el-table-column>
        <el-table-column prop="imageCount" label="图片">
          <template slot-scope="scope">
            <span style="color: #889ce5" @click="getImage(scope.row.id)">{{
              scope.row.imageCount
            }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="_Pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="查看图片"
      class="dialog"
      :visible.sync="showImage"
      width="40%"
    >
      <div style="padding-bottom: 20px">
        <div v-if="srcList.length == 0 && !showUpload" class="none">
          空空如也,<span style="color: blue" @click="showUpload = true"
            >去上传</span
          >
        </div>
        <div>
          <div v-if="srcList.length != 0">
            <span class="ImgDel" v-for="(item, index) in srcList" :key="item">
              <el-image
                style="width: 100px; height: 100px; margin-right: 3px"
                :src="item"
                :preview-src-list="srcList"
                @mouseover="overimg(index)"
              >
              </el-image>

              <i
                class="el-icon-delete-solid"
                v-show="index === Curindex"
                @click="delImg(item)"
              ></i>
            </span>
          </div>

          <el-upload
            v-if="showUpload || srcList.length != 0"
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            ref="upload"
            :http-request="uploadFile"
            :headers="token"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>
        <div
          v-if="showUpload || srcList.length != 0"
          @click="reqImage(picId)"
          class="upload-bottom"
        >
          确定
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, deleteMethod, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";

export default {
  data() {
    return {
      elementHeight: 0,
      tableData: [],
      CurId: null,
      companyList: [],
      company: null,
      deptList: [],
      dept: null,
      currentPage: 1,
      curSize:10,
      start: 0,
      end: 10,
      showImage: false,
      srcList: [],
      imgList: [],
      Curindex: null,
      showUpload: false,
      reqImageList: [],
      picId: 0,
      BUrl: baseURL[process.env.NODE_ENV + ""],
      token: {
        Authorization:
          "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "FileList") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.getElementHeight();
      this.companyList = this.$store.state.companyUserList;
    }
  },
  created() {
    this.company = JSON.parse(sessionStorage.getItem("userInfo")).upCompanyId;
    this.changeCompy(this.company);
    this.dept = JSON.parse(sessionStorage.getItem("userInfo")).upDeptmentId;
    this.toSearch();
  },
  methods: {
    clickTabItem(i) {
      if (i == 0) this.$router.push("/plan-page");
    },
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 40 + 70 + 70); //70是div底部距离底部的高度
      });
    },

    changeCompy(e) {
      this.dept = "";
      if (!e) return;
      get("/api/Department/GetNoTree?CompId=" + e).then((res) => {
        this.deptList = res.data;
      });
    },
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    toSearch() {
      get(
        "/api/NewFileAudit/GetByNewFileAudit?CompId=" +
          this.company +
          "&DeptId=" +
          this.dept
      ).then((res) => {
        this.tableData = res.data;
      });
    },
    change(e) {
      sessionStorage.setItem("Fitem", JSON.stringify(e));
      this.$router.push(
        "/file-list?Id=" +
          e.id +
          "&CpyID=" +
          e.companyId +
          "&deptID=" +
          e.deptId +
          "&postId=" +
          e.postId +
          "&planId=" +
          e.planId +
          "&year=" +
          e.completeTime.split("-")[0] +
          "&companyName=" +
          e.companyName
      );
    },
    cellStyle() {
      return "cursor:pointer;";
    },
    handleRow(e) {
      this.CurId = e.id;
    },
    // 上传图片
    overimg(index) {
      this.Curindex = index;
    },
    uploadFile(e) {
      if (e.file) {
        compressImage(e.file).then((result) => {
          if (result.size > e.file.size) {
            // 压缩后比原来更大，则将原图上传
            //调接口上传图片到服务器
            this.uploadFileReq(e.file);
          } else {
            //result压缩结果
            // 压缩后比原来小，上传压缩后的
            //调接口上传图片到服务器
            this.uploadFileReq(result);
          }
        });
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadImg", formData)
        .then((res) => {
          if (res.code == 200) {
            this.reqImageList.push(res.value);
          } else {
            this.$message.error(res.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    reqImage(fileAuditId) {
      if (this.reqImageList.length == 0) {
        this.showUpload = false;
        return;
      }
      var data = [];
      this.reqImageList.map((e) => {
        data.push({
          FileAuditId: fileAuditId,
          ImageRemark: "",
          ImageAddress: this.BUrl + "/uploads/" + e,
          SaveTime: new Date().toISOString(),
          SaveUserId: this.$store.state.userInfo.id,
        });
      });
      post("/api/FileAuditImage", {
        data: JSON.stringify(data),
      }).then(() => {
        this.tableData.map((i) => {
          if (fileAuditId * 1 == i.id) {
            i.imageCount += this.reqImageList.length;
          }
        });
        this.getImage(fileAuditId);
        this.$refs.upload.clearFiles();
      });
    },
    getImage(e) {
      this.picId = e;
      this.showImage = true;
      this.showUpload = false;
      this.srcList = [];
      this.reqImageList = [];
      get("/api/FileAuditImage/" + e).then((res) => {
        this.imgList = res.value;
        res.value.forEach((element) => {
          this.srcList.push(element.picNameAndAddr);
        });
      });
    },
    delImg(item) {
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod("/api/FileAuditImage?Id=" + e.id).then(() => {
                this.getImage(this.CurId);
              });
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.back-icon img {
  width: 15px;
}
._ImgBtn {
  background-color: #1361ff !important;
  color: white !important;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 200px !important;
}
.none {
  text-align: center;
}
._Tab {
  padding-bottom: 10px;
}
.dialog {
  /deep/ .el-upload--picture-card {
    width: 100px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 100px !important;
    height: 100px !important;
  }
  .image-add {
    display: flex;
  }
}
.upload-bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: blue;
  left: 0;
  text-align: center;
  color: white;
  padding: 10px 0;
}
</style>